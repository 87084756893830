.datapicker-range-container {
  margin-top: auto;
  margin-bottom: auto;

  .input-group {
    justify-content: flex-start;
  }
  @media screen and (min-width: 769px) {
    .resume-datepicker {
      margin: 0.8rem 0;
    }
  }
  @media screen and (max-width: 769px) {
    .datapicker-range-container.input-group
      .DateRangePicker
      .DateRangePickerInput {
      padding: 0.2rem 0.6rem;
    }
    .input-group-append {
      display: none;
      visibility: hidden;
    }
  }
}

.datapicker-range-container.input-group .DateRangePicker .DateRangePickerInput {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiOutlinedInput-input {
  padding: 14.5px 14px !important;
}

#autocomplete {
  height: 55px !important;
  padding: 10px 0 0 5px !important;
  box-sizing: border-box !important;
}

.MuiInputBase-input::-webkit-input-placeholder {
  font-size: 11px !important;
}
.MuiFormLabel-root {
  font-size: 11px !important;
}
.MuiInputLabel-outlined {
  font-size: 13px !important;
}

.MuiFormControl-root {
  margin: 8px 0 !important;
}

@media screen and (max-width: 500px) {
  .MuiOutlinedInput-adornedStart {
    padding-left: 0 !important;
    font-size: 12px !important;
  }
  .MuiFormControl-root {
    margin: 5px 0 !important;
  }
  .MuiOutlinedInput-input {
    padding: 14.5px 10px !important;
  }
  .MuiInputBase-input::-webkit-input-placeholder {
    font-size: 12px !important;
  }
  .MuiInputBase-input::-webkit-input-placeholder {
    font-size: 12px !important;
  }
  .makeStyles-checkbox-52 {
    padding: 15px !important;
  }
  .MuiButton-root {
    min-width: 0 !important;
  }
  .MuiFormLabel-root {
    font-size: 10px !important;
  }
}
.breadcrumb {
  background-color: transparent !important;
  border-bottom: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}

.breadcrumb-item {
  a {
    color: #1e5168 !important;
  }

  & + .breadcrumb-item {
    &::before {
      content: '>' !important;
      font-weight: normal;
    }
  }

  &.active {
    font-weight: bold;
  }
}

.MuiTableCell-head {
  span.Mui-disabled {
    svg {
      display: none;
    }
  }
}

.MuiTableCell-body{
  div {
    justify-content: center;
  }
}

.MuiTableCell-body.MuiTableCell-paddingNone {
  text-align: center;
}
.MuiTableCell-head {
  .MuiCheckbox-colorSecondary {
    color: #FFFFFF,
  }
  .MuiIconButton-colorSecondary {
    color: #FFFFFF,
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #FFFFFF,
  }
}
